@charset "utf-8";

/* dirt syntax highlighting (base16) */
$base00: #231e18;
$base01: #302b25;
$base02: #48413a;
$base03: #9d8b70;
$base04: #b4a490;
$base05: #cabcb1;
$base06: #d7c8bc;
$base07: #e4d4c8;
$base08: #d35c5c;
$base09: #ca7f32;
$base0a: #e0ac16;
$base0b: #b7ba53;
$base0c: #6eb958;
$base0d: #88a4d3;
$base0e: #bb90e2;
$base0f: #b49368;
$sans-serif: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", "Microsoft YaHei", "Noto Sans CJK", Arial,
  sans-serif;
@import "minimal-mistakes/skins/neon"; // skin
@import "minimal-mistakes"; // main partials
@mixin notice($notice-color) {
  color: white;
  background-color: $notice-color;
  margin: 1em 0 !important;
  code {
    background-color: mix(#000, $notice-color, 50%);
  }
  a {
    color: mix(#000, $notice-color, 75%);
  }
}
::selection {
  background: $base0d;
}
.notice--info {
  @include notice(#03a9f4);
}
.notice--warning {
  @include notice(#e67e22);
}
.notice--success {
  @include notice(#27ae60);
}
.notice--danger {
  @include notice(#c0392b);
}
details {
  margin: 1em 0;
}
details > * {
  margin: 1em 0;
}
ul li,
ol li {
  margin-bottom: 0.25em;
}
.author__urls.social-icons .fab {
  color: #fff6fb;
}
.homepage-feature > .feature__wrapper {
  display: flex;
}
p > code,
a > code,
li > code,
figcaption > code,
td > code {
  background: $base02;
}
